<template>
    <Pie
        v-if="ready"
        class="pie-chart"
        :chart-data="data"
        :chart-options="chartOptions"
    />
</template>

<script>
import _ from 'lodash';
import { Pie } from 'vue-chartjs/legacy';
import {
    Chart as ChartJS,
    Title,
    Tooltip,
    Legend,
    ArcElement,
    CategoryScale,
} from 'chart.js';

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale);

export default {
    name: 'PieChart',

    props: {
        data: {
            type: Object,
            required: true
        },
        title: {
            type: String,
            required: true
        },
        options: {
            type: Object,
            default: null
        }
    },

    data: vm => ({
        chartOptions: {
            responsive: true,
            maintainAspectRatio: true,
            plugins: {
                title: {
                    display: true,
                    text: vm.title
                },
                legend: {
                    display: false,
                    position: 'bottom'
                }
            }
        },
        ready: false
    }),

    mounted() {
        if (!_.isEmpty(this.options)) {
            this.chartOptions = _.assign(_.cloneDeep(this.options));
            this.chartOptions.plugins.title.text = this.title;
        }

        this.ready = true;
    },


    components: {
        Pie,
    },
};
</script>
